import React, { useEffect } from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
// import { initTranscend } from "src/modules/reducers/transcend"
import { useLocation } from "@reach/router"

import Header from "src/components/common/Header"
import Footer from "src/components/common/Footer"

import "src/styles/layout.scss"
import "src/styles/ft-regola-neue.css"
import SEO from "src/components/common/SEO"
// import { selectIsTranscendInitialized } from "src/modules/reducers/transcend"
import PasswordProtection from "src/components/common/PasswordProtection"

const Layout = ({ children, title, useDefaultHeader = true }) => {
  // const dispatch = useDispatch()
  // const isTranscendInitialized = useSelector(selectIsTranscendInitialized)

  // useEffect(() => {
  //   if (!isTranscendInitialized) {
  //     dispatch(initTranscend())
  //   }
  // }, [isTranscendInitialized])

  const location =
    useLocation() || (typeof window !== "undefined" && window.location)

  // get base url of domain and page slug
  const baseUrl = "https://www.maximumeffortchannel.com"
  const pageSlug = location.pathname
  const pageUrl = baseUrl + pageSlug

  return (
    <LayoutWrapper>
      <Helmet>
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <SEO title={title} />
      <Header />
      <MainElement>{children}</MainElement>
      <Footer />
    </LayoutWrapper>
  )
}

export default Layout

const LayoutWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
`

const MainElement = styled.main`
  flex: 1;
`
