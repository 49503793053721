import React from "react"
import styled from "styled-components"

import TiktokIcon from "src/assets/svg/icon-tiktok.svg"
import YoutubeIcon from "src/assets/svg/icon-youtube.svg"
import FacebookIcon from "src/assets/svg/icon-facebook.svg"
import InstagramIcon from "src/assets/svg/icon-instagram.svg"
import TwitterIcon from "src/assets/svg/icon-twitter.svg"

// import { useSelector } from "react-redux"
// import { selectIsTranscendInitialized } from "src/modules/reducers/transcend"

const getCurrentYear = () => {
  return new Date().getFullYear()
}

const Footer = () => {
  const currentYear = getCurrentYear()

  // Transcend
  // const isTranscendInitialized = useSelector(selectIsTranscendInitialized)
  // const [showTranscendLink, setShowTranscendLink] = React.useState(false)

  // const showTranscendHandler = e => {
  //   e.preventDefault()
  //   window.transcend.showConsentManager({
  //     viewState: "DoNotSellExplainer",
  //   })
  // }

  // const hideTranscendHandler = React.useCallback(
  //   e => {
  //     if (showTranscendLink && e.key === "Escape") {
  //       window.transcend.hideConsentManager()
  //     }
  //   },
  //   [showTranscendLink]
  // )

  // React.useEffect(() => {
  //   document.addEventListener("keydown", hideTranscendHandler)
  //   return () => document.removeEventListener("keydown", hideTranscendHandler)
  // }, [hideTranscendHandler])

  // React.useEffect(() => {
  //   if (isTranscendInitialized) {
  //     setShowTranscendLink(
  //       window.transcend?.ready &&
  //         window.airgap?.getRegimes &&
  //         window.airgap.getRegimes().has("DoNotSell")
  //     )
  //   }
  // }, [isTranscendInitialized])

  return (
    <StyledFooter>
      <FooterTopLinks>
        {/* <FooterNav>
          <a
            className="footer-link"
            // href="mailto:cflately@fubo.tv"
          >
            Link
          </a>
          <a
            className="footer-link"
            // href="mailto:press@fubo.tv"
          >
            Link
          </a>
          <a
            className="footer-link"
            // href="https://www.fubo.tv/news"
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </a>
        </FooterNav> */}
        <FooterSocialLinks>
          <a
            href="https://www.youtube.com/vancityreynolds"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <TiktokIcon /> */}
            <YoutubeIcon />
          </a>
          <a
            href="https://www.facebook.com/MaximumEffort/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://twitter.com/maximumeffort"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.instagram.com/maximumeffort/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        </FooterSocialLinks>
      </FooterTopLinks>

      <FooterMiddle>
        <FooterBlurb>
          Maximum Effort makes movies, tv series, content, ads, and cocktails
          for the personal amusement of Hollywood Star Ryan Reynolds. We
          occasionally release them to the general public.
        </FooterBlurb>

        {/* <FooterSupportLinks>
                        <a href="tel:+18445511005"><span>Call Us</span><span>844-551-1005</span></a>
                        <a href="https://support.fubo.tv/hc/requests/new" target="_blank" rel="noopener noreferrer">Email Us</a>
                    </FooterSupportLinks> */}
      </FooterMiddle>

      <FooterBottom>
        <FooterCopyright>© {currentYear} Maximum Effort Inc.</FooterCopyright>
        {/* <FooterPrivacy>
          {showTranscendLink && (
            <a className="footer-link" href="" onClick={showTranscendHandler}>
              Your Privacy Choices
            </a>
          )}
          <a
            className="footer-link"
            // href="/documents/USA/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>
          <a
            className="footer-link"
            // href="/documents/USA/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          <a
            className="footer-link"
            // href="/documents/USA/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>
        </FooterPrivacy> */}
      </FooterBottom>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  font-size: 14px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  background: #242424;
  font-weight: 800;

  a {
    color: #fff;
  }

  @media (min-width: 576px) {
    justify-content: flex-end;
  }
`

const FooterTopLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    margin-bottom: 20px;
    align-items: center;
  }

  a {
    text-decoration: none;
    margin: 0 5px;

    :first-child {
      margin-left: 0;
    }
  }
`
const FooterNav = styled.nav`
  font-size: 14px;
  font-weight: 800;
  line-height: 2;
  white-space: nowrap;
`
const FooterSocialLinks = styled.div`
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
  a {
    font-size: 14px;
    font-weight: 800;
    line-height: 2;
    white-space: nowrap;
  }
  svg {
    fill: #fff;
    opacity: 0.5;
  }
`
const FooterMiddle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
const FooterBlurb = styled.div`
  max-width: 643px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.13px;
  line-height: 21px;
  opacity: 0.6;

  @media (max-width: 600px) {
    max-width: 580px;
    text-align: center;
  }
`

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`
const FooterCopyright = styled.div`
  opacity: 0.6;
  color: #fff;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`
const FooterPrivacy = styled.div`
  font-size: 14px;
  font-weight: 800;
  line-height: 2;
  white-space: nowrap;
  opacity: 0.6;
  display: flex;
  gap: 12px;

  a {
    ${"" /* margin-right: 13px; */}
    text-decoration: none;
  }
`
